import React from 'react'
import Link from 'gatsby-link'

const SiteTitle = () => {
	return (
		<div id="personal-title-container">
			<Link to="/"><h2 id="personal-title">luis m. castañeda</h2></Link>
		</div>
	)
}

export default SiteTitle